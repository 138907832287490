import React, { useState, useEffect } from "react";
import { getWarrantyContactApi } from "../../lib/warrantyApi";

export default function HistoricUser() {
  const [contactNumber, setContactNumber] = useState(false);
  const recID = sessionStorage.getItem("recID");
  const accountType = sessionStorage.getItem("accountType");
  const email = accountType === "2" ? sessionStorage.getItem("email") : null;
    // If accountType = 2 (investor) then fetch financialInstanceID from the local storage else fetch financialInstanceID from session storage
  const financialInstanceID = accountType === "2" ? localStorage.getItem("financialInstanceID") : sessionStorage.getItem("userFinancialId");
  useEffect(() => {
    getWarrantyContactApi(recID, financialInstanceID, email)
      .then((res) => {
        setContactNumber(res.phone);
      })
      .catch((e) => {});
  }, [recID, financialInstanceID, email]);
  return (
      <div className="container">
          <div className="row justify-content-left">
              <div className="col col-md-6 text-left">
                  <h6>1 Year Warranty - Workmanship</h6>
                  <p className="mt-1 text-dark">This warranty covers components such as the roof, counter tops, doors, windows and
                      finished floor surfaces.</p>
              </div>
          </div>
          <div className="row justify-content-left">
              <div className="col col-md-6 text-left">
                  <h6 className="mt-2">2 Year Warranty - Distribution Systems</h6>
                  <p className="mt-1 text-dark">This warranty covers the electrical, plumbing, and mechanical distribution systems
                      in the home.</p>
              </div>
          </div>
          <div className="row justify-content-left">
              <div className="col col-md-6 text-left">
                  <h6 className="mt-2">10 Year Warranty - Structural</h6>
                  <p className="mt-1 text-dark">This warranty covers the designated loadbearing elements of the home, such as the
                      foundation system, floor framing system, load-bearing walls and roof framing.</p>
              </div>
          </div>
          <div className="row justify-content-left">
              <div className="col col-md-6 text-left">
                  <h6 className="mt-2">Manufacturer&#39;s Warranty</h6>
                  <p className="mt-1 text-dark">Many parts of a new home are backed by a separate manufacturer&#39;s warranty. These
                      include kitchen appliances, plumbing fixtures like faucets, roof shingles, HVAC equipment and more.
                      Claims should be made through that manufacturer&#39;s warranty program.</p>
              </div>
          </div>
          <div className="row justify-content-left mb-10">
              <div className="col col-md-6 text-left">
                  <h6 className="mt-2">One-Time Drywall Service</h6>
                  <p className="mt-1 text-dark">All new homes may experience drywall nail pops and cracks due to normal
                      settlement. We will address visible nail pops and significant drywall cracks one time during the first
                      year.</p>
              </div>
          </div>
      </div>
  );
}
